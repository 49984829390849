import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 720.000000 697.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,697.000000) scale(0.100000,-0.100000)">
         <path d="M0 3485 l0 -3485 40 0 40 0 0 3485 0 3485 -40 0 -40 0 0 -3485z"/>
<path d="M7120 3485 l0 -3485 40 0 40 0 0 105 c0 58 -3 105 -6 105 -4 0 -13
-8 -20 -17 -7 -10 -14 -13 -14 -7 0 6 9 19 20 29 20 18 20 31 20 3387 l0 3368
-40 0 -40 0 0 -3485z"/>
<path d="M3270 6575 c-772 -86 -1455 -434 -1980 -1010 -183 -201 -326 -408
-455 -660 -153 -300 -255 -630 -312 -1010 -22 -151 -26 -658 -5 -800 56 -386
145 -679 302 -997 100 -203 264 -459 352 -551 16 -16 28 -31 28 -34 0 -9 128
-151 200 -222 317 -314 692 -553 1113 -710 223 -83 379 -123 652 -167 137 -21
565 -30 732 -15 309 29 623 109 932 236 87 36 341 167 395 205 25 16 47 30 50
30 8 0 167 112 240 169 132 103 284 244 391 362 170 188 233 275 415 579 64
107 207 440 235 545 7 30 16 59 19 63 18 29 73 281 102 467 21 133 30 544 15
701 -83 901 -515 1681 -1226 2214 -182 136 -260 185 -479 296 -420 214 -902
326 -1395 323 -113 -1 -258 -7 -321 -14z m571 -255 c443 -45 864 -186 1234
-413 202 -123 433 -311 577 -469 46 -50 88 -96 93 -102 40 -43 156 -197 209
-276 394 -594 550 -1310 435 -2010 -66 -404 -218 -791 -448 -1140 -20 -30 -41
-62 -48 -70 -6 -8 -33 -43 -58 -77 -69 -93 -199 -236 -309 -340 -90 -85 -307
-258 -359 -287 -12 -6 -47 -28 -77 -48 -78 -50 -291 -160 -396 -204 -357 -148
-693 -214 -1089 -215 -241 0 -489 27 -655 71 -497 132 -826 298 -1195 600 -16
13 -98 92 -180 175 -135 134 -310 348 -372 453 -56 94 -134 234 -158 281 -40
80 -129 306 -154 391 -13 41 -29 95 -36 120 -98 326 -121 798 -59 1180 36 220
120 501 209 695 58 126 87 185 95 190 4 3 11 17 15 32 3 15 10 30 14 33 4 3
33 47 65 99 277 450 685 819 1172 1060 265 130 593 228 874 260 52 6 109 13
125 15 71 8 381 5 476 -4z m979 -76 c164 -55 409 -196 629 -362 121 -91 361
-319 421 -400 19 -26 37 -49 40 -52 3 -3 14 -18 24 -34 16 -25 16 -28 2 -23
-9 4 -16 1 -16 -4 0 -6 -9 -9 -19 -6 -13 3 -21 -1 -24 -12 -3 -11 -30 11 -98
79 -331 334 -590 527 -949 707 -110 56 -154 82 -132 80 17 -2 35 -7 39 -10 3
-4 13 -7 22 -7 11 0 11 3 -5 15 -18 15 -17 16 14 10 37 -8 43 7 10 23 -34 16
-10 14 42 -4z m-3752 -1291 c-2 -5 -10 -21 -18 -38 -8 -16 -17 -32 -20 -35
-19 -17 -127 -275 -185 -441 -75 -216 -139 -532 -161 -797 -5 -64 -10 -82 -20
-78 -8 3 -14 1 -14 -4 0 -5 -11 -7 -25 -4 -22 4 -25 1 -25 -23 -1 -26 -2 -26
-12 -9 -12 22 -5 237 13 378 36 285 126 578 266 868 57 118 92 174 94 149 0
-8 4 -4 9 9 12 28 34 30 25 2 -9 -29 21 -26 41 5 14 22 41 37 32 18z m5539
-1735 c-19 -338 -116 -695 -272 -1008 -89 -177 -95 -189 -134 -240 -15 -20
-21 -22 -34 -12 -12 10 -15 9 -20 -8 -3 -13 -13 -20 -29 -20 -13 0 -29 -5 -36
-12 -22 -22 -14 15 12 53 27 39 120 220 161 314 116 262 206 585 250 899 14
99 25 146 25 103 0 -9 5 -17 10 -17 6 0 10 5 10 10 0 6 9 10 19 10 10 0 21 7
25 15 14 37 19 7 13 -87z m-5107 -1754 c41 -40 93 -88 115 -107 22 -20 54 -48
70 -62 44 -39 250 -195 320 -242 89 -60 308 -183 400 -225 159 -72 195 -88
220 -94 14 -3 28 -11 32 -17 4 -6 -4 -8 -23 -3 -16 4 -36 7 -44 6 -8 0 -23 0
-33 -1 -15 0 -16 -3 -6 -15 11 -13 8 -14 -17 -9 -27 5 -27 5 -5 -5 13 -6 21
-13 18 -16 -14 -14 -281 101 -452 194 -108 60 -282 176 -385 258 -94 75 -340
319 -340 338 0 6 -9 17 -20 24 -11 7 -20 18 -20 25 0 6 7 3 15 -7 8 -11 15
-15 15 -8 0 6 7 9 17 5 12 -5 15 -2 11 13 -11 42 38 19 112 -52z"/>
<path d="M3410 6183 c-336 -32 -567 -89 -860 -210 -158 -66 -356 -176 -495
-275 -158 -113 -414 -349 -520 -482 -22 -27 -52 -63 -66 -80 -26 -31 -159
-224 -159 -232 0 -2 -19 -34 -41 -71 -99 -161 -252 -524 -231 -545 5 -5 174
-7 376 -6 l368 3 60 115 c158 302 367 540 643 732 374 261 838 387 1281 349
164 -14 247 -30 399 -76 105 -32 301 -109 315 -125 3 -3 34 -20 70 -39 69 -36
215 -131 290 -188 214 -162 430 -426 550 -671 l45 -92 362 0 c308 0 363 2 363
14 0 29 -99 276 -157 392 -168 335 -406 642 -668 859 -38 32 -72 61 -75 65
-11 16 -181 131 -260 177 -379 221 -773 345 -1215 383 -112 9 -296 11 -375 3z
m11 -252 c-12 -8 -13 -34 -8 -148 7 -136 7 -138 -12 -114 -13 18 -20 49 -25
122 -7 109 3 149 38 149 16 0 18 -2 7 -9z m203 -61 c22 -56 20 -135 -4 -184
-26 -52 -63 -81 -88 -67 -9 6 -11 11 -5 11 7 0 27 19 44 43 31 42 31 43 26
140 -3 53 -2 97 3 97 4 0 15 -18 24 -40z m494 5 c-4 -31 -1 -35 19 -35 18 0
23 -6 23 -25 0 -21 -5 -25 -29 -25 -28 0 -30 -2 -41 -72 -7 -40 -16 -83 -21
-96 -8 -20 -5 -23 21 -29 31 -6 36 -17 20 -43 -8 -13 -12 -12 -30 6 -44 43
-44 147 0 249 10 24 16 45 14 48 -6 6 14 57 22 57 3 0 4 -16 2 -35z m-186 -52
c2 -10 2 -69 0 -130 -4 -114 -12 -134 -41 -100 -13 17 -4 216 11 234 14 18 25
16 30 -4z m-166 -19 c-4 -9 -9 -15 -11 -12 -3 3 -3 13 1 22 4 9 9 15 11 12 3
-3 3 -13 -1 -22z m-947 -76 c1 -5 -6 -8 -14 -8 -19 0 -55 -39 -55 -59 1 -9 16
-56 35 -106 24 -64 31 -92 22 -97 -15 -9 -37 10 -37 33 0 10 -11 49 -25 87
-14 38 -25 81 -25 95 0 26 40 67 66 67 8 0 14 8 15 18 0 14 2 14 9 -3 5 -11 9
-23 9 -27z m115 25 c-5 -10 -8 -46 -8 -80 1 -67 -6 -78 -24 -38 -17 36 -15 86
4 113 18 27 40 30 28 5z m180 -18 c34 -70 17 -155 -36 -184 -38 -21 -45 -7
-15 28 30 36 40 85 27 131 -20 68 -4 84 24 25z m643 -43 c-13 -42 -27 -47 -27
-9 0 18 29 61 36 54 2 -1 -3 -22 -9 -45z m679 22 c4 -15 -4 -62 -20 -117 -14
-51 -26 -101 -26 -110 0 -23 -17 -21 -30 3 -8 16 -5 47 17 135 27 115 46 143
59 89z m310 -99 c-1 -58 -8 -68 -26 -36 -14 28 -12 52 7 73 9 10 17 18 18 18
1 0 1 -25 1 -55z m191 -48 c-9 -68 -26 -64 -37 10 -10 65 -2 83 23 52 14 -18
18 -33 14 -62z m-695 38 c-4 -36 -18 -46 -28 -21 -6 16 12 56 24 56 5 0 6 -16
4 -35z m-1788 -22 c-5 -10 -8 -46 -8 -80 1 -35 0 -63 -2 -63 -2 0 -10 13 -19
30 -19 37 -19 80 1 108 18 27 40 30 28 5z m170 -6 c54 -53 43 -171 -19 -199
-37 -17 -40 -6 -10 38 23 33 26 47 22 89 -2 28 -8 58 -12 68 -8 20 1 22 19 4z
m-349 -83 c15 -25 24 -49 20 -52 -4 -4 -10 -1 -12 6 -3 6 -8 10 -12 8 -4 -3
-13 4 -20 16 -7 11 -8 17 -2 13 14 -8 14 -3 1 29 -14 39 -5 31 25 -20z m2372
-44 c0 -22 -4 -40 -8 -40 -15 0 -22 50 -9 65 17 21 17 20 17 -25z m197 15 c9
-13 16 -38 16 -54 0 -38 -40 -87 -64 -78 -16 6 -16 8 -2 22 19 19 30 76 22
110 -8 32 7 32 28 0z m152 -91 c-33 -50 -56 -95 -56 -111 -1 -27 -1 -27 -16
-8 -9 12 -12 30 -9 44 8 30 100 168 117 174 28 9 18 -18 -36 -99z m-2827 35
c-7 -14 -9 -42 -5 -73 l6 -51 -20 25 c-32 40 -21 120 16 120 12 0 13 -4 3 -21z
m205 -59 c25 -35 46 -67 46 -70 0 -11 -51 -31 -61 -25 -5 4 -9 1 -9 -6 0 -19
-31 -49 -52 -49 -27 0 -22 15 17 44 48 37 54 77 19 141 -33 61 -22 51 40 -35z
m2826 20 c0 -6 -35 -62 -77 -124 -58 -84 -80 -109 -85 -97 -11 27 -9 37 18 67
14 16 46 59 71 97 41 60 73 85 73 57z m203 -6 c4 -4 -11 -29 -33 -57 -32 -39
-38 -53 -29 -64 17 -20 1 -30 -18 -11 -21 22 -11 58 28 103 31 35 40 40 52 29z
m-3498 -213 c7 -24 7 -24 -12 -5 -22 21 -37 67 -25 78 7 7 21 -21 37 -73z
m3355 44 c0 -3 -16 -26 -36 -52 -24 -32 -34 -54 -31 -70 6 -31 -21 -31 -29 1
-7 29 16 77 52 105 25 20 44 27 44 16z m170 -54 c0 -66 -88 -174 -126 -153
-14 8 -12 12 16 32 37 26 69 90 70 136 0 17 3 34 7 38 11 11 33 -24 33 -53z
m-3236 0 c3 -4 -4 -17 -15 -27 -20 -18 -21 -18 -35 1 -8 10 -14 22 -14 27 0
11 57 10 64 -1z m-203 -55 l-44 -44 19 -52 c16 -44 16 -52 4 -60 -8 -5 -18 -6
-22 -2 -12 12 -32 128 -25 146 6 15 79 55 102 56 5 0 -10 -20 -34 -44z"/>
<path d="M3752 5076 c-12 -14 -43 -34 -67 -46 l-44 -21 16 -35 c19 -40 25 -41
69 -13 l34 21 0 -161 0 -161 55 0 55 0 0 214 c0 133 -4 217 -10 221 -5 3 -11
5 -12 3 0 -2 -4 -88 -8 -193 l-6 -190 -2 168 c-2 139 -5 169 -17 173 -21 9
-19 19 8 32 20 10 19 11 -13 11 -24 1 -42 -7 -58 -23z"/>
<path d="M3940 4886 c0 -112 3 -211 6 -220 10 -27 76 -20 116 12 19 15 51 36
71 46 36 20 36 20 20 50 -9 17 -17 32 -18 34 -2 2 -20 -4 -40 -12 -21 -9 -39
-16 -41 -16 -2 0 -4 70 -4 155 l0 155 -55 0 -55 0 0 -204z m60 -193 c16 -4 18
-8 9 -19 -6 -8 -20 -14 -31 -14 -16 0 -19 5 -14 23 3 12 7 90 8 172 l2 150 3
-154 c3 -143 5 -153 23 -158z"/>
<path d="M3240 4720 c-18 -25 -20 -38 -15 -73 14 -85 60 -125 131 -114 67 11
110 72 100 141 -8 52 -18 67 -42 66 -67 -5 -121 -3 -135 3 -12 6 -23 0 -39
-23z"/>
<path d="M1131 4714 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2852 4613 c5 -103 33 -160 119 -243 90 -87 128 -116 180 -138 79
-33 142 -44 248 -43 181 2 265 36 271 112 4 46 0 75 -13 93 -13 18 -16 18 -68
1 -80 -27 -234 -24 -319 5 -36 13 -83 34 -105 48 -53 33 -152 134 -165 167 -5
14 -18 28 -27 31 -10 3 -34 14 -54 25 -66 34 -72 30 -67 -58z"/>
<path d="M2449 4464 c-15 -19 -21 -194 -7 -194 5 0 7 -4 4 -9 -4 -5 0 -12 6
-14 8 -3 4 -6 -7 -7 -17 -2 -16 -3 5 -10 21 -7 22 -8 5 -9 -11 -1 -27 -9 -34
-20 -10 -14 -20 -17 -40 -11 -14 4 -25 12 -24 19 10 42 -76 -38 -98 -93 -7
-17 -15 -33 -19 -36 -3 -3 -14 -25 -25 -50 -19 -43 -20 -65 -16 -660 1 -338 6
-619 9 -625 4 -5 7 -16 9 -24 6 -43 66 -60 211 -61 90 0 151 14 166 39 14 22
17 59 17 211 -1 175 3 186 66 201 53 12 360 11 405 -1 47 -13 50 -28 48 -212
-2 -79 -2 -145 0 -148 1 -3 3 -14 5 -25 2 -11 16 -30 31 -43 26 -22 33 -23
163 -20 109 2 142 7 163 20 24 16 63 18 340 20 l314 3 80 40 c43 22 82 42 87
43 4 2 7 -3 7 -11 0 -14 40 -29 165 -63 33 -8 108 -18 167 -21 145 -8 210 7
302 67 110 73 156 124 176 195 13 46 6 135 -10 135 -5 0 -21 25 -37 56 -31 62
-80 109 -145 142 -62 30 -191 82 -204 82 -7 0 -14 3 -16 8 -1 4 -25 18 -51 31
-61 31 -127 96 -126 125 0 11 10 39 22 61 19 34 29 41 67 48 55 11 150 -3 214
-32 57 -25 69 -51 60 -136 l-7 -63 44 -4 c55 -6 64 -3 78 29 8 18 17 24 31 20
11 -3 23 -1 26 4 3 5 12 56 20 112 7 56 17 115 20 130 5 25 4 28 -13 23 -11
-3 -44 2 -75 13 -30 10 -101 27 -157 37 -89 15 -121 16 -243 7 -77 -5 -143
-14 -146 -19 -4 -5 -20 -18 -35 -27 -17 -11 -26 -24 -23 -33 9 -25 -29 -16
-91 21 -98 58 -159 72 -351 80 -95 4 -172 11 -172 16 0 12 -47 11 -55 -1 -3
-5 -10 -10 -15 -10 -4 0 1 17 12 38 23 43 19 118 -8 159 -14 23 -20 24 -62 17
-26 -4 -166 -8 -312 -9 -146 0 -271 -4 -277 -8 -7 -5 -13 -19 -13 -33 0 -20
-5 -24 -32 -24 -52 0 -174 36 -210 61 -78 54 -113 88 -163 154 -68 90 -87 135
-89 215 -2 57 -5 65 -22 66 -10 0 -33 8 -51 17 -40 21 -47 21 -64 1z m61 -324
c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m85
-118 c88 -99 279 -220 430 -271 166 -56 316 -64 490 -27 61 13 119 27 129 31
22 10 16 -5 -18 -43 l-21 -23 23 -18 c36 -27 44 -117 40 -484 -2 -261 0 -330
11 -336 10 -7 10 -9 -2 -14 -7 -3 -20 -1 -28 4 -25 16 -49 10 -49 -11 0 -15
-7 -20 -29 -20 l-28 0 -7 373 c-4 204 -11 408 -14 451 -7 76 -8 79 -24 62 -10
-10 -18 -21 -18 -26 0 -22 -72 -40 -163 -40 -227 0 -510 116 -679 279 -46 43
-67 79 -31 50 17 -13 -16 33 -52 74 -9 10 -14 21 -12 24 3 2 -3 13 -14 24 -10
10 -19 25 -19 32 0 6 10 -1 23 -17 12 -16 40 -49 62 -74z m1519 -411 c69 -35
115 -89 148 -173 16 -40 22 -84 26 -185 5 -153 -11 -243 -59 -318 -16 -25 -29
-49 -29 -53 0 -18 -63 -35 -147 -39 -88 -5 -92 -4 -118 21 -21 21 -24 30 -15
45 6 13 10 148 10 374 0 194 3 357 8 361 15 16 117 -3 176 -33z m556 -457 c52
-20 109 -46 127 -60 43 -32 67 -99 61 -168 -3 -35 -1 -57 6 -59 18 -7 -64 -37
-98 -37 -58 0 -161 21 -203 41 -48 23 -72 69 -47 90 8 6 14 29 14 53 0 42 30
176 40 176 3 0 48 -16 100 -36z"/>
<path d="M5866 3869 c-3 -8 -19 -66 -37 -129 -17 -63 -35 -123 -40 -132 -7
-12 -4 -20 8 -27 17 -9 17 -10 0 -17 -28 -10 -186 1 -200 15 -10 10 7 12 81 9
50 -2 92 0 92 5 0 10 -257 9 -264 -1 -2 -4 37 -39 87 -77 112 -85 117 -89 117
-110 0 -9 -13 -70 -30 -135 -35 -137 -34 -136 -16 -143 11 -5 59 31 194 144
23 20 38 24 44 11 2 -4 23 -23 48 -42 25 -19 61 -49 80 -68 35 -33 75 -50 88
-38 3 4 -6 31 -22 61 -15 30 -25 59 -21 64 3 6 2 11 -2 11 -5 0 -14 24 -21 54
-15 63 -7 92 34 121 15 11 61 47 103 79 64 51 72 60 55 67 -20 8 -251 12 -243
4 3 -2 57 -5 120 -6 104 -2 111 -3 84 -15 -46 -20 -219 -19 -240 2 -9 9 -13
24 -10 35 4 10 3 19 -2 19 -11 0 -43 104 -52 173 -8 59 -26 93 -35 66z"/>
<path d="M1285 3848 c-9 -26 -75 -257 -75 -263 0 -3 -63 -5 -140 -5 -77 0
-140 -4 -140 -9 0 -10 55 -58 138 -119 43 -32 62 -52 62 -68 0 -12 -13 -75
-30 -140 -16 -66 -30 -122 -30 -126 0 -22 41 0 123 65 51 41 97 77 104 81 7 4
52 -25 111 -74 55 -44 104 -80 110 -80 23 0 22 21 -3 69 -14 28 -25 59 -25 71
0 11 -5 20 -11 20 -6 0 -9 7 -5 15 3 8 0 28 -6 45 -16 47 -2 67 110 150 56 41
101 78 102 81 0 13 -48 19 -158 19 -75 0 -111 -3 -107 -10 4 -7 49 -9 121 -7
85 3 112 1 104 -7 -6 -6 -59 -12 -124 -14 -125 -4 -146 4 -146 56 -1 15 -7 38
-15 52 -8 14 -14 37 -15 52 0 15 -4 30 -10 33 -5 3 -10 19 -10 34 0 67 -22
116 -35 79z m-58 -291 c10 -11 -68 -19 -132 -15 -36 2 -70 9 -76 15 -7 7 21 9
96 8 59 -2 109 -6 112 -8z"/>
<path d="M1037 2713 c-9 -15 72 -234 141 -383 104 -224 293 -501 462 -676 42
-44 68 -79 64 -86 -4 -6 -3 -8 2 -5 6 3 39 -19 74 -50 36 -30 92 -76 125 -101
33 -26 62 -50 63 -54 2 -4 10 -8 17 -8 7 0 15 -4 17 -8 4 -12 142 -103 245
-163 429 -248 873 -369 1351 -369 379 0 758 82 1096 236 306 139 537 297 775
529 272 265 468 551 613 895 53 127 88 233 79 243 -4 4 -168 6 -365 5 l-358
-3 -57 -110 c-167 -323 -394 -575 -691 -767 -219 -142 -430 -226 -730 -290
-93 -20 -134 -23 -355 -23 -237 0 -257 1 -384 28 -280 60 -504 154 -716 302
-116 81 -148 108 -276 235 -177 175 -289 326 -391 527 l-52 103 -372 0 c-205
0 -375 -3 -377 -7z m1073 -810 c0 -18 -126 -353 -132 -353 -20 0 5 151 33 196
10 17 19 47 19 67 0 24 9 46 26 66 24 28 54 42 54 24z m3146 -108 c-1 -59 -13
-66 -34 -21 -13 25 -12 30 9 52 12 13 23 24 25 24 1 0 1 -25 0 -55z m-3312 17
c-6 -4 -13 -15 -17 -24 -7 -20 -27 -24 -27 -7 0 13 30 38 45 39 6 0 6 -3 -1
-8z m3506 -11 c5 -11 10 -38 10 -60 0 -45 -23 -76 -64 -87 -13 -3 -26 -14 -29
-25 -7 -27 -27 -24 -27 5 0 18 8 27 30 35 41 14 65 68 51 116 -7 28 -7 35 4
35 8 0 19 -9 25 -19z m-3000 -116 c0 -3 -46 -76 -102 -162 -96 -147 -103 -154
-110 -128 -6 23 -2 35 24 64 17 19 42 60 56 91 25 57 96 140 119 140 7 0 13
-2 13 -5z m2693 -44 c9 -16 33 -48 52 -71 19 -23 35 -46 35 -51 0 -6 -15 -16
-32 -24 -18 -7 -41 -23 -50 -34 -11 -13 -24 -18 -34 -14 -13 5 -10 12 20 37
41 36 50 79 23 117 -9 13 -24 34 -33 47 -9 12 -12 22 -7 22 5 0 16 -13 26 -29z
m-2962 -57 c-12 -15 -24 -44 -27 -65 -8 -48 -24 -41 -24 11 0 29 7 46 32 71
36 39 52 24 19 -17z m2667 -15 c2 -9 -5 -19 -14 -23 -18 -7 -34 8 -34 32 0 19
44 11 48 -9z m162 -11 c0 -6 -7 -5 -15 2 -8 7 -15 17 -15 22 0 6 7 5 15 -2 8
-7 15 -17 15 -22z m-310 3 c0 -6 -4 -12 -9 -16 -5 -3 -13 -20 -17 -38 -6 -31
-7 -31 -17 -10 -7 17 -6 29 7 48 16 25 36 34 36 16z m-2210 -109 c0 -4 -7 -18
-16 -30 -9 -13 -19 -44 -23 -70 -7 -45 -7 -46 -20 -24 -15 28 -7 72 20 107 20
24 39 33 39 17z m150 -11 c27 -52 -6 -157 -57 -180 -13 -6 -26 -11 -28 -11
-10 0 -5 19 8 32 36 35 50 71 50 123 0 57 9 69 27 36z m2300 -63 c0 -32 -43
-83 -77 -93 -26 -7 -32 9 -8 19 18 7 48 72 40 86 -3 5 0 18 7 29 11 18 13 18
25 3 7 -9 13 -29 13 -44z m-2060 -15 c0 -34 -13 -40 -50 -22 -24 12 -30 19
-20 22 8 3 22 8 30 10 29 10 40 7 40 -10z m-105 -60 c-28 -92 -57 -163 -66
-157 -14 8 -11 36 11 99 16 48 53 105 66 105 2 0 -3 -21 -11 -47z m1685 -71
c15 -42 28 -83 31 -91 5 -18 -42 -26 -53 -9 -4 7 -8 25 -8 40 0 15 -7 46 -16
68 -18 44 -12 102 9 81 6 -6 23 -46 37 -89z m-1016 -51 c-6 -111 -9 -128 -20
-113 -20 25 -18 184 2 223 8 17 18 29 20 26 3 -2 2 -64 -2 -136z m709 110 c3
-13 -3 -22 -20 -30 -15 -7 -23 -17 -20 -26 3 -7 9 -42 12 -77 l6 -63 -20 43
c-38 80 -40 156 -3 165 33 9 40 7 45 -12z m140 -11 c-7 -28 -13 -25 -13 6 0
14 4 23 9 20 5 -3 7 -15 4 -26z m-659 -24 c41 -87 -7 -236 -75 -236 -20 0 -20
1 4 30 38 44 50 92 44 175 -5 79 1 86 27 31z m-654 25 c0 -5 -7 -22 -15 -37
-8 -16 -15 -49 -15 -73 0 -42 -1 -43 -15 -25 -20 27 -18 73 5 112 19 30 40 43
40 23z m150 -10 c14 -28 12 -96 -4 -136 -15 -34 -41 -55 -72 -55 -15 0 -14 4
8 28 34 36 51 93 43 142 -7 43 6 55 25 21z m814 -63 c11 -48 27 -201 22 -205
-2 -3 -13 -2 -24 1 -17 6 -21 19 -27 106 -4 54 -4 103 0 109 10 18 24 12 29
-11z m374 -20 c-14 -41 -14 -42 -20 -26 -7 17 8 58 21 58 6 0 6 -12 -1 -32z
m-526 -10 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m12 -98
c-6 -25 -9 -29 -16 -16 -5 10 -5 24 2 37 16 28 22 19 14 -21z m390 -6 c13 -34
-20 -46 -48 -18 -24 23 -19 34 13 34 17 0 31 -6 35 -16z"/>
<path d="M6180 1990 c-6 -11 -7 -20 -2 -20 6 0 12 9 15 20 3 11 3 20 1 20 -2
0 -8 -9 -14 -20z"/>
<path d="M6116 1961 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M1410 1520 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6821 4634 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
